import React, { useEffect} from 'react';
import Navbar from '../../shared/navBar';

function TpaService() {
    useEffect(() => {
        document.title = "Technology Platform adoption Services by ConceptTrends";
      }, []);
  return (
    <section className="no-sidebar">
      <section id="navbar">
        <div id="header">
          <div className="inner">
              <header>
                  <h1>Technology Platform adoption Services</h1> 
                  <p>Levelling up your business tech</p> 
              </header>
          </div>
          <Navbar />
        </div>
      </section>
      <section className="wrapper style1">
            <div className="container">
                <article id="main" className="special">
                    <section >
                        <header >
                            <h3>Levelling up your business tech</h3>
                        </header>
                        
                        <p>Choosing the correct technology platform can be overwhelming.<br/><br/>ConceptTrends is here to help you identify the appropriate platforms for your business needs and develop your new technology strategy, design your business case for change and provide a roadmap for that journey. <br/><br/>From strategic development through to implementation, our goal is to ensure your technology and business processes are fully integrated, scalable and secure.<br/><br/>And to help ensure your workforce knows what they’re doing!
                        </p>
                            
                    </section>
                </article>
                </div>
      </section>
      <img src="../images/adoption2.jpg" alt="" className="full-img" />	
    </section>
    );
}

export default TpaService;