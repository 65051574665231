import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import './App.css';
import MainPage from './pages/main-page/mainPage';
import Footer from './shared/footer';
import About from './pages/about/about';
import ExecutiveRecruitment from './pages/services/executive-recruitment';
import Saas from './pages/services/saas';
import WebDevelopment from './pages/services/web-development';
import MobileApps from './pages/services/mobile-apps';
import TaService from './pages/services/technology-advisory-services';
import TpaService from './pages/services/technology-platform-adoption-services';
import ScrollToTop from './shared/ScrollToTop.js';

function App() {
  const routes = (
    <Switch>
      <Route path='/' exact>
        <MainPage />
      </Route>
      <Route path='/about' exact>
        <About />
      </Route>
      <Route path='/personnel-solutions' exact>
        <ExecutiveRecruitment />
      </Route>
      <Route path='/services/saas' exact>
        <Saas />
      </Route>
      <Route path='/services/web-development' exact>
        <WebDevelopment />
      </Route>
      <Route path='/services/mobile-apps' exact>
        <MobileApps />
      </Route>
      <Route path='/services/technology-advisory-services' exact>
        <TaService />
      </Route>
      <Route path='/services/technology-platform-adoption-services' exact>
        <TpaService />
      </Route>
      <Redirect to='/' />
    </Switch>
  );
  return (
    <div id='page-wrapper' className='App'>
      
      <Router>
        <ScrollToTop />
        <main>{routes}</main>
      </Router>
      <Footer />

    </div>
    
  );
}

export default App;