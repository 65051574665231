import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export default class Navbar extends React.Component {
    constructor(props) {
    super(props);
    

    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.state = {
      dropdownOpen: false
    };
    
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  onMouseEnter() {
    this.setState({dropdownOpen: true});
  }

  onMouseLeave() {
    this.setState({dropdownOpen: false});
  }
  

  render() {



    return (
         
              
              <nav id="nav">
                  
                  <ul>
                      <li className="logo">
                      <Link to="/"><img src="/images/ct-logo-white.png" alt="Logo" /></Link>
                      </li>
                      <li>
                          <Dropdown  onMouseOver={this.onMouseEnter} onMouseLeave={this.onMouseLeave} isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle caret id="dropdownMenuButton"><Link to="/services/saas">Services</Link></DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem ><Link to="/services/saas">SaaS</Link></DropdownItem>
                                <DropdownItem ><Link to="/services/web-development">Web development</Link></DropdownItem>
                                <DropdownItem ><Link to="/services/mobile-apps">Mobile Apps</Link></DropdownItem>
                                <DropdownItem ><Link to="/services/technology-advisory-services">Your IT support system</Link></DropdownItem>
                                <DropdownItem ><Link to="/services/technology-platform-adoption-services">Technology Platform adoption Services</Link></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                      </li>
                      <li><Link to="/personnel-solutions">Personnel Solutions</Link></li>
                      <li><Link to="/about">About us</Link></li>
                      <li><AnchorLink href='#contact'>Contact</AnchorLink></li>
                  </ul>
              </nav>
  
         
    );
  }
} 
