import React, { useEffect} from 'react';
import Navbar from '../../shared/navBar';

function Saas() {
	useEffect(() => {
        document.title = "SaaS by ConceptTrends";
      }, []);
  return (
    <section className="no-sidebar">
      <section id="navbar">
        <div id="header">
          <div className="inner">
              <header>
                  <h1>Software as a Service (SaaS)</h1> 
                  <p>Centrally-hosted, globally available.</p> 
              </header>
          </div>
          <Navbar />
        </div>
      </section>
      <section className="wrapper style1">
        <div className="container">
						<article id="main" className="special">
							<section>
								<img className="image right" src="../images/saas-1.jpg" alt="" />
								<header className="mt4">
									<h3><strong>Your SaaS solution</strong></h3>
								</header>
								<p>
								Subscription-based services and products are an incredibly effective way of delivering regular, semi-passive income to your business, while engaging with customers on an ongoing basis.<br/><br/>Our software engineering teams have developed SaaS solutions across a wide range of industries, so regardless of your sector we can build you something incredible.
								</p>
							</section>
						</article>
					</div>
					<img src="../images/saas-2.jpg" alt="" className="full-img mt6" />
					<div className="container mt2">
						<article id="main" className="special">
							<section>
								<header>
									<h3><strong>Why SaaS?</strong></h3>
								</header>
								<p>
								There are numerous advantages to developing Software as a Service (SaaS) over a traditional software product. The main one is its cloud-based nature, meaning no installations are required by the end user, updates are easy to roll out, and the build itself can be iterative.<br/><br/>
So you can start with an idea, build a minimum viable product, and get selling straight away!<br/><br/>

With hundreds of thousands of hours of experience, our team will help get your next SaaS solution built at speed, and help you get it right first time.

								</p>
							</section>
						</article>
					</div>
      </section>
    </section>
    );
}

export default Saas;