import React, { useEffect} from 'react';
import Navbar from '../../shared/navBar';

function MobileApps() {
    useEffect(() => {
        document.title = "Mobile App Development by ConceptTrends";
      }, []);
  return (
    <section className="no-sidebar">
      <section id="navbar">
        <div id="header">
          <div className="inner">
              <header>
                  <h1>Mobile App Development</h1> 
                  <p>Innovative iOS and Android applications</p> 
              </header>
          </div>
          <Navbar />
        </div>
      </section>
      <section className="wrapper style1">
        <div className="container">
						<article id="main" className="special">
							<section >
                    
										<header className="mt2">
											<h3><strong>Mobile App</strong> Development</h3>
										</header>
										<p >
                    Whether your business needs an app for your staff, your customers, or you want to launch something new to the general public, we can build it.  
                    </p>
										<p>Our expertise in custom mobile app development gives our clients the opportunity to pack incredible functionality into their customer’s palms. All the mobile apps we build deliver a world-class user experience, with intuitive functionality, gorgeous design and powerful features.</p>
									
							</section>
                        </article>
                    </div>
                    <img src="../images/mobile-2.jpg" alt="" className="full-img mt6" />
                    <div className="container">
                        <article>
							<section className="mt6">
										<header className="mt2">
											<h3>Experts <strong>across platforms</strong> </h3>
										</header>
										<p>
                      Our clients need apps that perform across different mobile devices and operating systems. And our team delivers for them.</p><p>We’re experts at using Java, Kotlin and C++ to build Android apps and Swift for native Android app development.</p><p>Along with React Native for closs-platform and hybrid development
										</p>	
							</section>
							<section className="mt6">
										<header className="mt2">
											<h3><strong>Hybrid mobile</strong> application development</h3>
										</header>
										<p>
                      Depending on the needs of our client, hybrid mobile apps can offer certain advantages over native apps.</p><p> For clients looking to quickly build a platform that works across web and mobile, hybrid mobile apps reduce development time and cost.</p><p>One codebase can be used for both iOS and Android apps, and standing code can be reused from web platforms.
										</p>
							</section>
              <section className="mt6">
										<header className="mt2">
											<h3><strong>Full-service</strong> solutions</h3>
										</header>
										<p>
                    If you have an app you want to build, we can deliver. 
                    </p>
                    <p>
                    Speak to us about the project and we’ll talk you through how we’ll help you take it from an idea to featuring in an app store in record time.
										</p>
							</section>
						</article>
					</div>		
      </section>
    </section>
    );
}

export default MobileApps;