import React, { useEffect} from 'react';
import Navbar from '../../shared/navBar';
import AnchorLink from 'react-anchor-link-smooth-scroll';

function ExecutiveRecruitment() {
    useEffect(() => {
        document.title = "Executive Recruitment by ConceptTrends";
      }, []);
  return (
    <section className="no-sidebar">
      <section id="navbar">
        <div id="header">
          <div className="inner">
              <header>
                  <h1>Personnel Solutions</h1> 
                  <p></p> 
              </header>
          </div>
          <Navbar />
        </div>
      </section>
      <section className="wrapper style1">
        <div className="container">
                <article id="main" className="special">
                    <section>
                        <p>
                        Getting the right staff on board is essential in any business. But it’s hard work, takes far too long and can be expensive. <br/><br/>
                        Our approach is unique. Over decades of working in the tech sector, we have established a network of hundreds of IT professionals, from diverse and multicultural backgrounds, ready to hit the ground running for you.<br/><br/>
                        We won’t simply put a CV in front of you and expect you to do the work – we have vetted our personnel and have experience of working with them ourselves.<br/><br/>
                        So just let us know what you’re looking for, and we’ll take it from there.
                        </p>
                        <div className="row personnel-lists">
                            <div className="col-6 col-12-small">
                                <h3>Hiring made easy</h3>
                                <ul>
                                    <li>Vetted candidates</li>
                                    <li>Semi-permanent or permanent hires</li>
                                    <li>Trained to hit the ground running</li>
                                    <li>Money back guarantee on fees</li>
                                </ul>
                            </div>
                            <div className="col-6 col-12-small">
                                <h3>Outsourced project management</h3>
                                <ul>
                                    <li>Self-sufficient teams</li>
                                    <li>Regular sprint runs</li>
                                    <li>Immense experience</li>
                                    <li>Ready to start instantly</li>
                                </ul>
                            </div>
                            <div className="col-12 text-center">
                                <AnchorLink href='#contact' className="button  text-white w250">Get in Touch</AnchorLink>
                            </div>
                        </div>
                    </section>
                </article>
            </div>
    </section>
    <section className="wrapper style1 project-bg">
        <div className="container ">
            <article id="main" className="special">
                <section>
                    <h3 className="text-center text-white mt2">Expertise in the following areas, ready to get to work for you:</h3>
                    <ul className="mt2 mb2"> 
                        <li>IT support
                        </li>
                        <li>Program/Project Management
                        </li>
                        <li>PMO Analysis & Support
                        </li>
                        <li>DevOps
                        </li>
                        <li>Scrum personnel
                        </li>
                        <li>Agile coaches & leadership
                        </li>
                    </ul>
                    
                </section>
            </article>
        </div>
	</section>
    <section className="wrapper style1">
        <div className="container">
             <article id="main" className="special mt2">
                <section>
                    
                    <p className="text-special-center">
                        We have an excellent reputation for successfully delivering projects on time, on budget and providing BAU support.
                    </p>
                    {/*
                    <div>
                        <ul className="horizontal-graph"> 
                            <li><i className="fa fa-box-open"></i>IT Strategy & Solution design</li>
                            <li><i className="fa fa-code"></i>Implementation / migration</li>
                            <li><i className="fa fa-users-cog"></i>Training</li>
                            <li><i className="fa fa-life-ring"></i>IT support</li>
                        </ul>
                    </div> */}
                    
                </section>
            </article> 
           
            <article id="main" className="special">
                <section>
                    
                    {/* <header>
                        <h3>Project management | Product development | Product innovation
                        </h3>
                    </header>
                    <p>
                        XX was founded with the purpose of streamlining project management and product delivery operations to support our public and private sector clients and maximizing value creation  and turn areas of stagnation into growth.
                        This ethos lies at the heart of our firm.

                    </p> */}
                    
                    <div>
                    <h3 className="mt2">Training</h3>
                    <p>As well as staffing your business and delivering projects for you, we can upskill your own team with training and support in the following areas:
                    </p>
                    <ul> 
                        <li>E- learning
                        </li>
                        <li>Prince2
                        </li>
                        <li>SAFe
                        </li>
                        <li>Scrum at scale
                        </li>
                        <li>PSM
                        </li>
                        <li>Project & delivery management</li>
                    </ul>
                    </div>
                    
                </section>
            </article>
            {/* <article id="main" className="special mt6">
                <section>
                    <img src="images/performance-icon.jpg" className="image right"alt="" />
                    <header>
                        <h3><strong>Performance</strong> Improvement
                        </h3>
                    </header>
                    <p>
                        Comprehensive organizational reviews, Business process review and re modelling, PMO health checks. Our proposals for process improvement have lead our clients to elevate performance and increase customer experience.

                    </p>
                    </section>
            </article> */}
        </div>
    </section>
    <section className="wrapper style1 project-bg-2">
        <div className="container ">
            <article id="main" className="special">
                <section>
                    <p>We are an equal opportunity firm, and our diverse and multicultural network of personnel have all completed extensive diversity & inclusion training.
                    <br/><br/>
                    We also provide Racial literacy training and guidance to organisations, delivered by our award-winning instructors.
                        </p>
                </section>
            </article>
        </div>
	</section>
</section>

    );
}

export default ExecutiveRecruitment;