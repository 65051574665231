import React, { useEffect} from 'react';
import Navbar from '../../shared/navBar';

function WebDevelopment() {
    useEffect(() => {
        document.title = "Web development by ConceptTrends";
      }, []);
  return (
    <section className="no-sidebar">
      <section id="navbar">
        <div id="header">
          <div className="inner">
              <header>
                  <h1>Web development</h1> 
                  <p>only the best web technology</p> 
              </header>
          </div>
          <Navbar />
        </div>
      </section>
      <section className="wrapper style1">
					<div className="container">
						<article id="main" className="special">
							<section>
								
										<header className="mt2">
											<h3><strong>How we code</strong></h3>
										</header>
										<p>Every website is different, but our build process boils down into 3 parts – backend, frontend, and UX.
										</p>
										<p>This means your website will be built on robust foundations, will look clean and user-friendly, and be a pleasure to navigate.</p>
									
							</section>
							{/* <section>
								<div className="row w100">
									<article className="col-4 col-12-mobile special">
										
										<header>
											<h3><strong>Backend Web</strong> development</h3>
										</header>
										<p className="services-icon">
											<i className="fa fa-database"></i>
										</p>
									</article>
									<article className="col-4 col-12-mobile special">
										
										<header>
											<h3><strong>Frontend Web</strong> development</h3>
										</header>
										<p className="services-icon">
											<i className="fa fa-file-code"></i>
										</p>
									</article>
									<article className="col-4 col-12-mobile special">
										
										<header>
											<h3><strong>UI/UX</strong> design</h3>
										</header>
										<p className="services-icon">
											<i className="fa fa-object-group"></i>
										</p>
									</article>
								</div>
							</section> */}

							<section className="mt6">
								<div className="row w100">
									<div className="col-6 col-12-mobile col-12-sm special">
										<img className="image-services" src="../images/backend1.jpg" alt="" />
									</div>
									<div className="col-6 col-12-mobile col-12-sm special">
										<header className="mt0">
											<h3><strong>Backend Web</strong> development</h3>
										</header>
										<p>Rock-solid, reliable backend development ensures your business logic works as itshould.
										</p>
										<p>Our focus is on building super-fast, scalable, database-driven web and mobile applications. Our backend team has built up hundreds of hours of experience, with many clients across a variety of different industries and verticals.</p>
									</div>
								</div>
							</section>

							<section className="mt6">
								<div className="row w100">
									<div className="col-6 col-12-mobile col-12-sm special">
										<header className="mt0">
											<h3><strong>Frontend Web</strong> development</h3>
										</header>
										<p>
										Frontend development comprises 3 pillars: clean code, beautiful design and intuitive usability. We guarantee your website will have all three.
										</p>
										<p>We focus on the most popular JavaScript frameworks: Angular, React and Vue</p>
									</div>
									<div className="col-6 col-12-mobile col-12-sm special">
										<img className="image-services" src="../images/frontend1.png" alt=""  />
									</div>
									
								</div>
							</section>

							<section className="mt6">
								<div className="row w100">
									<div className="col-6 col-12-mobile col-12-sm special">
										<img className="image-services" src="../images/ui-ux1.jpg" alt="" />
									</div>
									<div className="col-6 col-12-mobile col-12-sm special">
										<header className="mt0">
											<h3><strong>UI/UX</strong> design</h3>
										</header>
										<p>
										Our team designs beautiful websites that are intuitive, functional, and a joy to use. 
										</p>
										<p>We rapidly prototype wireframes, mockups and visuals to help inform the design process. Using clickable figma prototypes we comprehensively test the user experience, validating the user flow and interaction design before we start writing code. Learnings from these prototypes can then be built into the product from an early stage – resulting in better products and lower cost.</p>
									</div>
								</div>
							</section>
						</article>
					</div>
      </section>
    </section>
    );
}

export default WebDevelopment;