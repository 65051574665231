import React, { useState, useEffect }  from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useCookies } from 'react-cookie';
import { useHttpClient } from '../hooks/http-hook';

function Footer() {
    const [disabledButton, setDisabledButton] = useState(true);
    const [captchaVisible, setCaptchaVisible] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [cookies, setCookie, removeCookie] = useCookies(['date']);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
  
    const { sendRequest } = useHttpClient();
  
    const onCaptchaChange = (value) => {
      setCaptcha(value);
    };
  
    const clearForm = () => {
      setName('');
      setPhone('');
      setEmail('');
      setMessage('');
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      if (captcha === '' || captcha === null) {
        return window.alert('Invalid Captcha');
      }
      const date = new Date();
      try {
        await sendRequest(
          //'http://localhost:3001/send',
          'https://concepttrends.herokuapp.com/send',
          'POST',
          JSON.stringify({
            name: name,
            email: email,
            phone: phone,
            company: 'company',
            message: message,
          }),
          {
            'Content-Type': 'application/json',
          }
        );
        setCookie('date', new Intl.DateTimeFormat('en-GB').format(date), '/');
        clearForm();
      } catch (err) {
        console.log(err, 'contactUs ERROR');
      }
    };
  
    const handleContactUsAgain = () => {
      setCaptcha('');
      setCaptchaVisible(false);
      setDisabledButton(true);
      removeCookie('date');
    };
  
    const validateEmail = (email) => {
      // eslint-disable-next-line
      if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
        setCaptchaVisible(true);
        return true;
      }
      return false;
    };
  // eslint-disable-next-line
    useEffect(() => {
      if (name !== '' && message !== '' && captcha !== '' && captcha !== null) {
        setDisabledButton(false);
      } else {
        setDisabledButton(true);
      }
    });
  
  return (
    
    <section id="contact">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <section className="contact">
                        <header className="mt2">
                            <h3>How can we help you?</h3>
                        </header>
                        <p>Leave us your details and we’ll get in touch as soon as possible.</p>
                    </section>
                    <>
                    {cookies?.date?.length ? (
                    <section className="contact contact-form">
                        <h2 className='major'>Thanks for reaching out!</h2>
                        <p>You contacted us on {cookies.date}</p>
                        <form>
                            <br />
                            <input
                            type='submit'
                            value='Contact Us Again?'
                            style={{ width: '-webkit-fill-available' }}
                            onClick={handleContactUsAgain}
                            />
                        </form>
                    </section>
                    ) : (
                    <section className="contact contact-form">
                        <form method="post" action="#">
                            <div className="row gtr-50">
                                <div className="col-4 col-12-small">
                                    <input required
                                    type='text'
                                    name='name'
                                    id='name'
                                    placeholder='Name'
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }} />
                                </div>
                                <div className="col-4 col-12-small">
                                    <input required
                                    type='email'
                                    name='email'
                                    id='email'
                                    placeholder='Email'
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        validateEmail(e.target.value);
                                    }} />
                                </div>
                                <div className="col-4 col-12-small">
                                    <input type="text"
                                    name='phone'
                                    id='phone'
                                    placeholder='Phone'
                                    onChange={(e) => {
                                        setPhone(e.target.value);
                                    }} />
                                </div>
                                <div className="col-12">
                                    <textarea 
                                    required
                                    name='message'
                                    id='message'
                                    placeholder='Message'
                                    rows='4'
                                    onChange={(e) => {
                                      setMessage(e.target.value);
                                    }}></textarea>
                                </div>
                                <div className="col-12">
                                <ReCAPTCHA
                                    sitekey='6LeDmjkbAAAAAI3NIlN7Yy_FMG0DUERUsjq8eW2N'
                                    //sitekey='6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
                                    onChange={onCaptchaChange}
                                    className='align-center'
                                    style={{
                                        ...(!captchaVisible && { display: 'none' }),
                                        width: '304px',
                                        height: '78px',
                                        margin: 'auto',
                                        paddingTop: '8px',
                                    }}
                                />
                                </div>
                                <div className="col-12">
                                    <input 
                                    disabled={disabledButton}
                                    type='submit'
                                    value='Send Message'
                                    onClick={handleSubmit} />
                                </div>
                            </div>
                        </form>
                    </section>
                    )}
                    </>
                    <section className="contact">
                        <p className="mail-footer">
                            <i className="fa fa-paper-plane"></i><a href="mailto:info@concepttrends.com">info@concepttrends.com</a><br/>
                            <i className="fa fa-phone"></i><a href="tel:+44 7894 466269">+44 7894 466269</a>
                        </p>
                        {/*<ul className="icons">
                            <li><a href="#" className="icon brands fa-twitter"><span className="label">Twitter</span></a></li>
                            <li><a href="#" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a></li>
                            <li><a href="#" className="icon brands fa-instagram"><span className="label">Instagram</span></a></li>
                            <li><a href="#" className="icon brands fa-pinterest"><span className="label">Pinterest</span></a></li>
                            <li><a href="#" className="icon brands fa-dribbble"><span className="label">Dribbble</span></a></li>
                            <li><a href="#" className="icon brands fa-linkedin-in"><span className="label">Linkedin</span></a></li>
                        </ul>*/}
                    </section>
                    <div className="copyright">
                        <ul className="menu">
                            <li>&copy; Concept Trends Ltd, London. All rights reserved.</li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>
    );
}

export default Footer;