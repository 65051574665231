import React, { useEffect} from 'react';
import Navbar from '../../shared/navBar';

function About() {
    useEffect(() => {
        document.title = "About ConceptTrends";
      }, []);
  return (
    <section className="no-sidebar">
      <section id="navbar">
        <div id="header">
          <div className="inner">
              <header>
                  <h1>Who we are</h1> 
                  <p></p> 
              </header>
          </div>
          <Navbar />
        </div>
    </section>
    <section className="wrapper style1 pb0">
        <div className="container">
            <article id="main" className="special">
                <section>
                    
                    <header>
                        <h3><strong>Why ConceptTrends?</strong></h3>
                    </header>
                    <p>
                    Concept Trends is a team of developers, project managers and leadership coaches.
<br/><br/>
We understand the difficulties that businesses go through in finding new talent, whether that’s a new hire or an outsourced team, as we’ve been there ourselves, countless times.
<br/><br/>
We’ve also built hundreds of products and systems from scratch, as well as picking up the pieces of an abandoned project and re-building it.
<br/><br/>
And yes, we’ve overhauled entire IT systems too for dozens of companies, implementing the best technology for the job and rejuvenating stagnating and archaic processes, pain free. 
<br/><br/>
You and your business can save a vast amount of time and money by putting our experience to use.
<br/><br/>
Just let us know what challenges you need to overcome, and we’ll let you know how we can help.
<br/><br/>
No obligation, no cost – just free advice on a discovery call.
<br/><br/>
And if you do decide to work with us, you’ll get your money back if you’re not totally satisfied.


                    </p>
                    
                </section>
            </article>
        </div>
        <img src="images/about2.jpg" alt="" className="full-img mt6" />
        {/* <div className="container mt2">
            <article id="main" className="special">
                <section>
                    
                    <header>
                        <h3><strong>ConceptTrends.</strong> A digital Project Management Office</h3>
                    </header>
                    <p>
                    In addition to managing the impact of disruption by new technology, we also need to consider how new technology adoption impacts our day-to-day roles.New technology can be used effectively to support us to be more efficient.<br/><br/>At ConceptTrends our digital project management team combine human insight, business understanding and new technology to harmonise the old with the new.We’ll provide you with a structured framework to get the most from your staff and your technological resources to maximise output during your business’s parallel journey of product, people and ecosystem development.<br/><br/>Driving transformation change management outcomes through leadership, people, cloud, technology, and behaviours.
                    </p>
                    
                </section>
            </article>
		</div> */}
    </section>

</section>

    );
}

export default About;