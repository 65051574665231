import React, { useEffect} from 'react';
import Navbar from '../../shared/navBar';

function TaService() {
    useEffect(() => {
        document.title = "Technology Advisory Services by ConceptTrends";
      }, []);
  return (
    <section className="no-sidebar">
      <section id="navbar">
        <div id="header">
          <div className="inner">
              <header>
                  <h1>Your IT support system</h1> 
                  <p>You have needs. We have solutions.</p> 
              </header>
          </div>
          <Navbar />
        </div>
      </section>
      <section className="wrapper style1">
            <div className="container">
                <article id="main" className="special">
                    <section >
                        <header className="mt2 mb2" >
                            <h3>Tech advice? We've got you covered</h3>
                        </header>
                        <p>Our clients have hundreds of different technological needs, but we’ve never found a problem we couldn’t help with in some way.</p><p>Whether it’s a growth-suppressing legacy system that needs bringing into the fast-changing digital world, a multitude of platforms that won’t communicate with each other, or some other technological issue in your business, our team can help you identify the way forward.</p><p>We support you through your journey, starting with a strategy that lays future-proof foundations.</p><p>We develop roadmaps for change that integrate with your workforce and business processes through secure cyber networks.</p><p>In short, if you have a technological need, we’ll be your solution.
                        </p>      
                    </section>
                </article>
                </div>	
                
      </section>
      <img src="../images/advisory1.jpg" alt="" className="full-img" />	
    </section>
    );
}

export default TaService;