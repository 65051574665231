import React from 'react';
import Navbar from '../../shared/navBar';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';

function MainPage() {
  return (
    <section className="homepage">
    <section id="navbar">
      <div id="header">
        <div className="inner">
            <header>
                <h1>ConceptTrends</h1>  
                <p>Technology & personnel solutions your business has been waiting for</p>
            
            </header>
            <footer>
				<AnchorLink  href="#go" className="font-2" ><i className="fa fa-angle-down"></i></AnchorLink>
            </footer>
        </div>
        <Navbar />
      </div>
    </section>
    <section id="mainPage">
      <section id="go">
			<header>
				<h2>Why Concept Trends?</h2>
			
				<p className="do-text">
				You’re looking for a solution. <br/>
				<span>We’ve been there.</span>
				You’re stuck with a technical problem, and can’t decide on the way forward.<br/>
				<span>We’ve been there.</span>
				Whether it’s hiring tech personnel, outsourcing a project, or trying to put together an IT overhaul.<br/>
				<span>We’ve been there.</span>
				Talk to us, with no fee or obligation, and we’ll find your solution.
				</p>
			</header>
		</section>
		<div className="fixed-bg"></div>
      	{/* <section id="banner" className="bg-grey">
			<header>
				<p className="do-list">
					<span>Got an idea for a piece software? <strong>We’ll build it for you.</strong></span>
					<span>Need to make a new hire in a technical role? <strong>We’ll find you the perfect candidate.</strong></span>
					<span>Need to make a new hire in a technical role? <strong>We’ll find you the perfect candidate.</strong></span>
					<span>Working on something that you need taking to the finish line? <strong>We’ll project manage it for you.</strong></span>
					<span>Need to make a new hire in a technical role? <strong>We’ll find you the perfect candidate.</strong></span>
					<span>Want to adopt new technology within your business? <strong>We’ll find you a solution and implement it, (virtually) pain-free.</strong></span>
				</p>
			</header>
		</section> */}
      	<section className="carousel">
        <div className="container">
					<div className="reel">
						<article>
							<Link to="/executive-recruitment" className="image featured"><img src="images/advisory2.jpg" alt="" /></Link>
							<header>
								<h3><Link to="/personnel-solutions">Personnel Solutions</Link></h3>
							</header>
							<p><span>Got an idea<br/>for a piece software? <strong><Link to="/personnel-solutions">We’ll find you the perfect candidate.</Link></strong></span></p>
						</article>
						<article>
							<Link to="/services/saas" className="image featured"><img src="images/saas-graphic.jpg" alt="SaaS" /></Link>
							<header>
								<h3><Link to="/services/saas" >Software as a Service (SaaS)</Link></h3>
							</header>
							<p><span>Need to make a new hire in a technical role? <strong><Link to="/services/saas" >We’ll build it for you.</Link></strong></span></p>
						</article>
						<article>
							<Link to="/services/web-development" className="image featured"><img src="images/web-development.jpg" alt="" /></Link>
							<header>
								<h3><Link to="/services/web-development" >Web development</Link></h3>
							</header>
							<p><span>Working on something that you need taking to the finish line? <strong><Link to="/services/web-development" >We’ll project manage it for you.</Link></strong></span></p>
						</article>

						<article>
						<Link to="/services/mobile-apps" className="image featured"><img src="images/mobile-app.jpg" alt="" /></Link>
							<header>
								<h3><Link to="/services/mobile-apps">Mobile apps</Link></h3>
							</header>
							<p><span>There isn’t an app<br/>for everything yet. <strong><Link to="/services/mobile-apps">We build them all day, every day, at a fraction of the cost you’d normally pay.</Link></strong></span></p>
						</article>
						<article>
						<Link to="/services/technology-platform-adoption-services" className="image featured"><img src="images/adoption-service.jpg" alt="" /></Link>
							<header>
								<h3><Link to="/services/technology-platform-adoption-services">Technology Platform adoption Services</Link></h3>
							</header>
							<p><span>You know you need to streamline some parts of your business.<strong><Link to="/services/technology-platform-adoption-services">We know which platform will do the best job.</Link></strong></span></p>
						</article>
						<article>
						<Link to="/services/technology-advisory-services" className="image featured"><img src="images/technology-advisory.jpg" alt="" /></Link>
							<header>
								<h3><Link to="/services/technology-advisory-services">Your IT support system</Link></h3>
							</header>
							<p><span>Want to adopt new technology within your business? <strong><Link to="/services/technology-advisory-services">We’ll find you a solution and implement it, (virtually) pain-free.</Link></strong></span></p>
						</article>
					</div>
          </div>
				</section>
    </section>
  </section>
    );
}

export default MainPage;